@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-rounded
}

@keyframes colorShift {
  0%, 100% {
    background-size: 200% 200%;
    background-position: right bottom;
  }
  50% {
    background-size: 200% 200%;
    background-position: left top;
  }
}

.gradient-animated {
  background-image: linear-gradient(to right, #4fd1c5, #4684cf);
  animation: colorShift 5s ease-in-out infinite; /* Slower animation */
}

/* Typography Enhancements */
h1, h2 {
  letter-spacing: 0.05em;
}

p, li {
  font-weight: 300; /* Lighter font for body text */
}